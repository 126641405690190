import submitPasswordForm from './submitPasswordForm';

const resetPasswordForm = document.getElementById(
  'reset_password_form',
);

if (resetPasswordForm) {
  resetPasswordForm.addEventListener('submit', (e) =>
    submitPasswordForm(e),
  );
}
